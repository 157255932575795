import React from 'react'

// Components
import Layout from '../components/Layout'


const NotFoundPage = () => (
  <Layout>
    <div className="container">
      <h1>404</h1>
      <p>Pagina niet gevonden.</p>
    </div>
  </Layout>
)

export default NotFoundPage
